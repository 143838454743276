import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Alert, Backdrop, Box, CircularProgress, Snackbar, Typography } from '@mui/material'

import DetectedSection from './DetectedSection'
import ProofVerificationDetailSection from './ProofVerificationDetailSection/ProofVerificationDetailSection'

import demoServerAxios from '../../../axios/demoServerAxios'
import verificationAxios from '../../../axios/verificationAxios'

const VerifyDetail = () => {
    const tokenId = useParams()
    const [backdropOpen, setBackdropOpen] = useState(true)
    const [proofTokenInfo, setProofTokenInfo] = useState({ vin: null, fileName: '', downloadFileLink: '', txHash: '' })
    const [detailData, setDetailData] = useState(null)

    const onVerifyDone = () => {
        setBackdropOpen(false)
    }

    useEffect(() => {
        setDetailData(null)
        if (tokenId && tokenId !== '') {
            setProofTokenInfo({ vin: null, fileName: '', downloadFileLink: '', txHash: '' })
            fetchProofTokenInfo(tokenId)
        }
    }, [])

    const fetchFile = (url) => {
        return fetch(url, {
            method: 'GET',
        }).then((res) => {
            return res.blob()
        })
    }

    const fetchProofTokenInfo = async ({ tokenId }) => {
        console.log('tokenId:', tokenId)
        return new Promise((myResolve, myReject) => {
            demoServerAxios
                .get(`/rest/proof-token/tree/${tokenId}`)
                .then((res) => {
                    console.log('fetchProofTokenInfo() res: ', res)
                    const proofFileName = res.data.proofTokenFileName
                    const downloadProofTokenUrl = res.data.downloadProofTokenUrl
                    const vin = res.data.treeCode?.toLocaleUpperCase()
                    const txHash = res.data.tokenTxHash
                    const tokenId = res.data.tokenID
                    if (downloadProofTokenUrl && proofFileName) {
                        fetchFile(downloadProofTokenUrl)
                            .then((blob) => {
                                let url = URL.createObjectURL(blob)
                                let link = document.createElement('a')
                                link.href = url
                                link.download = proofFileName
                                document.body.appendChild(link)
                                setProofTokenInfo({
                                    vin: vin,
                                    fileName: proofFileName,
                                    downloadFileLink: link,
                                    txHash: txHash,
                                    tokenId: tokenId,
                                })
                                const file = new File([blob], proofFileName)
                                handleVerify([file], vin)
                            })
                            .catch((error) => {
                                console.log('error: ', error)
                                myReject(error)
                                handleSnackbarOpen()
                            })
                    }
                })
                .catch(() => {
                    handleSnackbarOpen()
                })
        })
    }

    const handleVerify = (acceptedFiles, vin) => {
        const formData = new FormData()
        acceptedFiles.forEach((file) => {
            formData.append('verification-proof', file)
            verificationAxios({
                method: 'post',
                url: '/verifyProof',
                data: formData,
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then((res) => {
                    console.log('handleVerify() res: ', res)
                    if (res.data.status === 'ok') {
                        const verifyResult = { ...res.data, vin }
                        setDetailData(verifyResult)
                    }
                })
                .catch(() => {
                    handleSnackbarOpen()
                })
                .finally(() => onVerifyDone())
        })
    }

    const [snackbarOpen, setSnackbarOpen] = useState(false)
    const [isDetectFail, setIsDetectFail] = useState(false)
    const handleSnackbarOpen = () => {
        setIsDetectFail(true)
        setBackdropOpen(false)
        setSnackbarOpen(true)
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <>
            <Box px={6} py={6}>
                <Typography variant="h5" color="textPrimary">
                    驗證資料完整性
                </Typography>
                <DetectedSection
                    isVerifyDone={detailData && Object.keys(detailData).length !== 0}
                    proofTokenInfo={proofTokenInfo}
                    isDetectFail={isDetectFail}
                />
                <Box mt={4}>
                    {detailData && (
                        <ProofVerificationDetailSection detailData={detailData} proofTokenInfo={proofTokenInfo} />
                    )}
                </Box>
            </Box>
            <Backdrop open={backdropOpen} sx={{ backgroundColor: 'rgb(0, 0, 0, 0.8)' }}>
                <CircularProgress />
            </Backdrop>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    查無資料，請確認查詢資訊是否正確
                </Alert>
            </Snackbar>
        </>
    )
}

export default VerifyDetail
