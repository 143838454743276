import axios from '../axios/demoServerAxios'

const fetchResultWithId = (treeID) => {
    return new Promise((myResolve, myReject) => {
        axios
            .get(`/rest/art/nft/tree-info/${treeID.toLocaleLowerCase()}`)
            .then((res) => {
                console.log('res: ', res)
                myResolve(res)
            })
            .catch((error) => {
                console.log('error: ', error)
                myReject(error)
            })
    })
}

export { fetchResultWithId }
