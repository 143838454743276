import React, { useState, useRef, useEffect } from 'react'
import { Stack, Divider, Box, Snackbar, Alert } from '@mui/material'
import Submission from './SubmissionArea/Submission'
import VerifyResult from './VerifyResult/VerifyResult'

const Home = () => {
    const [data, setData] = useState(null)
    const [progressDone, setProgressDone] = useState(false)

    const ref = useRef(null)
    const scrollToSection = () => {
        ref.current.scrollIntoView({ behavior: 'smooth' })
    }

    useEffect(() => {
        if (data && progressDone) {
            setTimeout(() => {
                scrollToSection()
            }, 500)
        }
    }, [data, progressDone])

    const [snackbarOpen, setSnackbarOpen] = useState(false)

    const handleSnackbarOpen = () => {
        setSnackbarOpen(true)
    }
    const handleSnackbarClose = () => {
        setSnackbarOpen(false)
    }

    return (
        <>
            <Stack spacing={10} px={8} py={12}>
                <Submission setData={setData} setProgressDone={setProgressDone} onApiFail={handleSnackbarOpen} />
                {data && (
                    <>
                        <Box>
                            <Divider />
                            <div ref={ref}></div>
                        </Box>
                        <VerifyResult data={data} />
                    </>
                )}
            </Stack>
            <Snackbar
                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                open={snackbarOpen}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    查無資料，請確認查詢資訊是否正確
                </Alert>
            </Snackbar>
        </>
    )
}

export default Home
