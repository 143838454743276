import { Backdrop, Box, Typography, styled, InputBase, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useParams } from 'react-router-dom'
import ProgressBar from './StepperWithProgressBar/ProgressBar'
import { fetchResultWithId } from '../../../api/resultApi'

const CustomInput = styled(InputBase)(({ theme }) => ({
    '& .MuiInputBase-input': {
        borderRadius: 50,
        backgroundColor: theme.palette.common.white + '80',
        border: '1px solid',
        borderColor: '#00000066',
        fontSize: 14,
        padding: '10px 30px',
        transition: theme.transitions.create(['border-color']),
        '&:focus': {
            border: '2px solid',
            borderColor: theme.palette.primary.main,
        },
    },
}))

const Submission = ({ setData, setProgressDone, onApiFail }) => {
    const { treeId } = useParams()
    const [inputValue, setInputValue] = useState('')
    const [isProgressing, setIsProgressing] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const onInputChange = (e) => {
        setInputValue(e.currentTarget.value?.toLocaleUpperCase())
    }

    const onBackropClose = () => {
        setTimeout(() => {
            setIsProgressing(false)
        }, 400)
    }

    const onSubmit = (treeId) => {
        setIsProgressing(true)
        setInputValue('')
        setData(null)

        fetchResultWithId(treeId)
            .then((res) => {
                setData(res.data)
            })
            .catch(() => {
                setIsProgressing(false)
                onApiFail()
            })
    }

    useEffect(() => {
        if (inputValue === '' || inputValue === null) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [inputValue])

    useEffect(() => {
        if (treeId && treeId !== '') {
            onSubmit(treeId)
        }
    }, [])

    return (
        <Box>
            <Box display="flex" flexDirection="column" alignItems="center" sx={{ textAlign: 'center' }}>
                <Typography variant="serifTitle">YOUR CODE</Typography>
                <Typography variant="h6" sx={{ mt: 1, fontWeight: 400, letterSpacing: '0.09em' }}>
                    輸入您的編碼，查閱區塊鏈
                </Typography>
                <Box width="100%" textAlign="-webkit-center" mt={5}>
                    <Box sx={{ width: { lg: '35%', md: '45%', sm: '80%', xs: '100%' } }}>
                        <CustomInput
                            fullWidth
                            placeholder="TWILAT223-E076-07"
                            value={inputValue}
                            onChange={onInputChange}
                        />
                    </Box>
                </Box>
                <Button variant="contained" disabled={disabled} sx={{ mt: 5 }} onClick={() => onSubmit(inputValue)}>
                    <Typography
                    // fontWeight={500}
                    >
                        SUBMIT
                    </Typography>
                </Button>
                <Box mt={6}>
                    <Typography variant="body1">
                        區塊鏈履歷存證與查驗系統：由ITM提供
                        <br />
                        區塊鏈系統：由ITM提供
                        <br />
                        碳匯計算系統：由台大林管處提供
                    </Typography>
                </Box>
                <Box mt={4} maxWidth={700}>
                    <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.7 }}>
                        依照聯合國清潔發展機制「於濕地以外施行的小規模造林及再造林減量方法」(Simplified baseline and
                        monitoring methodology for small-scale CDM afforestation and reforestation project activities
                        implemented on lands other than wetlands) (AR-AMS0007)
                        或是環保署「造林與植林碳匯專案活動」進行森林碳匯監測，以達到永續發展目標。
                    </Typography>
                </Box>
                <Box mt={3} maxWidth={850}>
                    <Typography variant="body2" color="text.secondary" sx={{ lineHeight: 1.7 }}>
                        通過綠色區塊鏈帶來更高級別的透明度
                        <br />
                        GPE GreenChain 使用先進的區塊鏈（分佈式賬本）技術提供更高級別的植樹透明度：
                        <br />
                        GPE好事交易所的每一個永續訂閱專案都存儲在 GPE 的 GreenChain 上並線上查驗與回溯驗證。
                        <br />
                        任何人都可以使用 GreenChain 查看所參與的訂閱專案，包括交易歷史。沒有秘密！
                        <br />
                        GreenChain 基於 ITM 所提供之 Blockchain Notary Service（BNS）進行各式訊息存證與驗證，並藉由 dNFT
                        技術針對每株植株建立 NFT 並動態紀錄其生長過程。
                        <br />
                        ITM
                        所提供之服務建立於公有區塊鏈中，這是領先的公有區塊鏈存證及NFT鑄造平台，可實現行動與交易透明化。
                        <br />
                        客戶可以在 GreenChain 上搜尋現下驗證“節點”。
                        <br />
                        GreenChain 是碳中性的，因為 GPE 採取公民森林沖銷了 GreenChain
                        的碳足跡；實際上，由於其智能實施，為此只需要很少的樹。
                    </Typography>
                </Box>
            </Box>
            <Backdrop
                sx={{
                    zIndex: 1,
                    color: 'primary',
                    backgroundColor: 'rgb(0, 0, 0, 0.8)',
                }}
                open={isProgressing}
                transitionDuration={{ exit: 500 }}
            >
                <Box mt={3} sx={{ maxWidth: '65%' }}>
                    <ProgressBar
                        onBackropClose={onBackropClose}
                        setProgressDone={setProgressDone}
                        isProgressing={isProgressing}
                    />
                </Box>
            </Backdrop>
        </Box>
    )
}

Submission.propTypes = {
    setData: PropTypes.func.isRequired,
    setProgressDone: PropTypes.func.isRequired,
    onApiFail: PropTypes.func.isRequired,
}

export default Submission
