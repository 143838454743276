import React from 'react'
import PropTypes from 'prop-types'
import { LinearProgress, Typography } from '@mui/material'
import { theme } from '../../../../theme/Theme'

const CustomStepper = ({ activeStep, progress }) => {
    const steps = ['資料讀取中', '區塊鏈證據讀取中', '證據驗證中', '驗證完成！']

    const StepNumber = ({ index }) => {
        return (
            <Typography variant="serifNumTitle" color="common.white" sx={{ fontSize: theme.typography.h4.fontSize }}>
                {`0${index + 1}`}
            </Typography>
        )
    }

    return (
        <ul
            style={{
                width: '100%',
                padding: 0,
                display: 'flex',
                flexDirection: 'row',
            }}
        >
            {steps.map((label, key) => {
                let step = key
                const active = step <= activeStep

                const stepStyle = {
                    position: 'relative',
                    display: 'flex',
                    justifyContent: 'space-around',
                    alignItems: 'center',
                    flexDirection: 'row',
                }

                const labelContainerStyle = {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    textAlign: 'center',
                    width: 95,
                    height: 95,
                    margin: theme.spacing(4, 0, 7),
                    padding: theme.spacing(1),
                    borderRadius: '50%',
                    border: active ? `2px solid ${theme.palette.primary.main}` : null,
                    transition: 'all 1s linear',
                }

                return (
                    <li style={stepStyle} key={key}>
                        <div style={labelContainerStyle}>
                            <StepNumber index={key} />
                            <Typography
                                variant="stepLabel"
                                sx={{
                                    color: 'white',
                                    margin: 0,
                                }}
                            >
                                {label}
                            </Typography>
                        </div>
                        {step !== steps.length - 1 && (
                            <LinearProgress
                                variant="determinate"
                                value={(activeStep === step && progress) || (activeStep > step && 100)}
                                sx={{
                                    mx: 2,
                                    flexGrow: 1,
                                    width: 120,
                                    marginBottom: 4,
                                    position: 'relative',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    height: 2,
                                }}
                            />
                        )}
                    </li>
                )
            })}
        </ul>
    )
}

CustomStepper.propTypes = {
    activeStep: PropTypes.number.isRequired,
    progress: PropTypes.number,
    index: PropTypes.number,
}

export default CustomStepper
