import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import CustomStepper from './CustomStepper'

const ProgressBar = ({ onBackropClose, setProgressDone, isProgressing }) => {
    const [progress, setProgress] = useState(0)
    const [activeStep, setActiveStep] = useState(0)

    const randomBarValue = (timesValue, maxValue) => {
        if (progress < 90) {
            const timer = setTimeout(() => {
                setProgress((prev) => {
                    const diff = Math.random() * timesValue
                    return Math.min(prev + diff, maxValue)
                })
            }, 100)

            return () => {
                clearTimeout(timer)
            }
        } else if (progress > 90) {
            const timer = setTimeout(() => {
                setActiveStep((prev) => prev + 1)
                setProgress(0)
            }, 100)

            return () => {
                clearTimeout(timer)
            }
        }
    }

    const handleProgressSpeedInStep = () => {
        if (activeStep === 1) {
            randomBarValue(10, 100)
        } else if (activeStep === 2) {
            randomBarValue(8, 100)
        } else {
            randomBarValue(25, 100)
        }
    }

    useEffect(() => {
        if (activeStep <= 3) {
            if (isProgressing) {
                handleProgressSpeedInStep()
            }
        } else {
            onBackropClose()
            setProgressDone(true)
            setTimeout(() => {
                // initial stepper after backdrop close
                setActiveStep(0)
                setProgressDone(false)
            }, 1000)
        }
    }, [progress, isProgressing])

    return <CustomStepper activeStep={activeStep} progress={progress} />
}

ProgressBar.propTypes = {
    onBackropClose: PropTypes.func.isRequired,
    setProgressDone: PropTypes.func.isRequired,
    isProgressing: PropTypes.bool.isRequired,
}

export default ProgressBar
