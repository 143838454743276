import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { v4 as uuidv4 } from 'uuid'
import { TableRow, TableCell, Box, Typography, Button } from '@mui/material'
import UnfoldMoreIcon from '@mui/icons-material/UnfoldMore'
import UnfoldLessIcon from '@mui/icons-material/UnfoldLess'

import VerifyStatus from './VerifyStatus'

const CollapseRow = ({ dataArr, renderMoreInfo, renderClearanceOrder }) => {
    const [expand, setExpand] = useState(false)

    const handleHiddenRowExpand = () => {
        setExpand((prev) => !prev)
    }

    const renderCOAndLimitLengthIndexValue = (data) => {
        const iv = data.indexValue
        return (
            <>
                <TableCell align="left">
                    <Typography variant="body2">{renderClearanceOrder(data)}</Typography>
                    <Typography variant="body2">{iv}</Typography>
                </TableCell>
            </>
        )
    }

    return (
        <>
            <TableRow id="expand-hidden-row" key={uuidv4()} sx={{ backgroundColor: expand && 'background.light' }}>
                <TableCell align="left" colSpan={12} sx={{ padding: 0 }}>
                    <Box display="flex">
                        <Button
                            startIcon={
                                expand ? <UnfoldLessIcon fontSize="small" /> : <UnfoldMoreIcon fontSize="small" />
                            }
                            onClick={handleHiddenRowExpand}
                            sx={{ pl: 1, flexGrow: 1, justifyContent: 'flex-start', borderRadius: 0 }}
                        >
                            {expand ? '隱藏驗證資料' : '顯示隱藏的驗證資料'}
                        </Button>
                    </Box>
                </TableCell>
            </TableRow>
            {dataArr &&
                dataArr.map((data, index) => (
                    <React.Fragment key={`collapse-row-${index}`}>
                        {expand && (
                            <TableRow sx={{ backgroundColor: 'background.light' }}>
                                <TableCell align="left">
                                    <Typography variant="body2">上鏈紀錄結尾證據</Typography>
                                </TableCell>
                                <TableCell align="left" />

                                {renderCOAndLimitLengthIndexValue(data)}
                                <TableCell align="center">
                                    <VerifyStatus status={data.proofExistStatus} iconOnly={true} />
                                </TableCell>
                                <TableCell align="left" />
                                <TableCell align="left" />
                                <TableCell align="center">{renderMoreInfo(data)}</TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                ))}
        </>
    )
}

CollapseRow.propTypes = {
    dataArr: PropTypes.array.isRequired,
    renderMoreInfo: PropTypes.func.isRequired,
    renderClearanceOrder: PropTypes.func.isRequired,
}

export default CollapseRow
