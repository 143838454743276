import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { styled, Box, Typography, Divider, Card, CardMedia, CardContent, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import itmLogo from '../../../image/ITM-Logo.png'
import ownerProfile from '../../../image/owner-profile.png'
import { renderTime, renderYearAndMonth } from '../../../util/stringUtil'

const numberTitleWidth = 120
const numberTitleHeight = 120

const VerticalDecoBar = styled(Box)(({ theme }) => ({
    width: 26,
    height: '100%',
    background: theme.palette.primary.main,
    borderRadius: 20,
    position: 'absolute',
    opacity: 0.9,
    top: 0,
    left: 47,
    zIndex: -1,
}))

const DetailRowBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}))

export const DetailCard = styled(Card)(({ theme }) => ({
    padding: theme.spacing(2),
    width: 600,
    display: 'flex',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white + '80',
}))

export const DetailCardContent = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    gap: 2,
})

const VerifyResult = ({ data }) => {
    const etherscanUrlPrefix = 'https://goerli.etherscan.io'
    const baseUrl = window.location.origin
    return (
        <Box gap={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Typography variant="h5">區塊鏈驗證結果</Typography>
            <Box mt={1} gap={2} display="flex" alignItems="center">
                <Typography variant="h6">CODE：{data.treeCode?.toLocaleUpperCase()}</Typography>
                <Typography variant="h6">/</Typography>
                <Box gap={1} display="flex" alignItems="center">
                    <Typography variant="h6">整體驗證結果：</Typography>
                    <CheckIcon />
                    <Typography variant="h6">驗證成功</Typography>
                </Box>
                <Typography variant="h6">/</Typography>
                <Typography variant="h6">驗證時間：{renderTime(moment.now())}</Typography>
            </Box>
            {/* TODO extract detail cards? */}
            <Box
                display="flex"
                flexDirection="column"
                alignItems="flex-start"
                m={2}
                mt={6}
                sx={{ position: 'relative' }}
            >
                <VerticalDecoBar />
                <DetailRowBox>
                    {/* TODO extract number title */}
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{
                            width: numberTitleWidth,
                            height: numberTitleHeight,
                            borderRadius: '50%',
                            border: '1px solid black',
                        }}
                    >
                        <Typography variant="serifNumLargeTitle" sx={{ fontWeight: 'bold' }}>
                            01
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    {/* TODO extract card */}
                    <DetailCard variant="outlined">
                        <CardMedia component="img" sx={{ width: 140, height: 140, m: 1 }} src={data.treePhotoUrl} />
                        <DetailCardContent sx={{ ml: 3 }}>
                            <Typography variant="body1">品種：{data.treeType}</Typography>
                            <Typography variant="body1">種植時間：{renderYearAndMonth(data.plantTime)}</Typography>
                            <Typography variant="body1">地點：{data.location}</Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            02
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        <CardMedia
                            component="img"
                            sx={{ ml: 4, width: 161, height: 52 }}
                            src={data.validateOrgLogoUrl}
                        />
                        <DetailCardContent sx={{ ml: 3 }}>
                            <Typography variant="body1">記錄單位：{data.validateOrg}</Typography>
                            <Typography variant="body1">記錄時間：{renderYearAndMonth(data.validateTime)}</Typography>
                            <Typography variant="body1">地點：{data.validateLocation}</Typography>
                            <Typography variant="body1">CODE：{data.treeCode?.toLocaleUpperCase()}</Typography>
                            <br />
                            <Typography variant="body1">碳平衡能力計算方式公信單位：{data.trustOrg}</Typography>
                            <Typography variant="body1">碳匯計算系統：{data.carbonSystem}</Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            03
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        <CardMedia component="img" sx={{ ml: 4, width: 150, height: 70 }} src={itmLogo} />
                        <DetailCardContent sx={{ ml: 4 }}>
                            <Typography variant="body1">上鏈單位：{data.clearanceOrg}</Typography>
                            <Box display="flex">
                                <Typography variant="body1">NFT 資訊：</Typography>
                                <Typography
                                    component="a"
                                    href={`${etherscanUrlPrefix}/tx/${data.txHash}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    variant="body1"
                                    color="primary.main"
                                >
                                    查看鏈上資訊
                                </Typography>
                            </Box>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID：{data.tokenID}
                            </Typography>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                Token ID(10進制)：{BigInt('0x' + data.tokenID, 16).toString()}
                            </Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
                <Divider orientation="vertical" sx={{ ml: 7.5, height: 100, borderColor: 'common.black' }} />
                <DetailRowBox>
                    <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        sx={{ width: numberTitleWidth, height: numberTitleHeight, borderRadius: '50%' }}
                    >
                        <Typography variant="serifNumTitle" sx={{ fontWeight: 'bold' }}>
                            04
                        </Typography>
                    </Box>
                    <Divider sx={{ width: 30, borderColor: 'common.black' }} />
                    <DetailCard variant="outlined">
                        {data.ownerPictureUrl ? (
                            <CardMedia
                                component="img"
                                sx={{ ml: 5, width: 90, height: 90 }}
                                src={data.ownerPictureUrl}
                            />
                        ) : (
                            <CardMedia component="img" sx={{ ml: 5, width: 120, height: 120 }} image={ownerProfile} />
                        )}
                        <DetailCardContent sx={{ ml: 6 }}>
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                持有者名稱：David Lo
                            </Typography>
                            {data?.ownerAddress && (
                                <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                    持有者錢包地址：{data.ownerAddress}
                                </Typography>
                            )}
                            <Typography variant="body1" sx={{ wordBreak: 'break-all' }}>
                                mail：{data.email ? data.email : 'modo@gmail.com'}
                            </Typography>
                            <Typography variant="body1">
                                訂閱所屬名稱：{data.subscribeName ? data.subscribeName : 'GPE'}
                            </Typography>
                            <Typography variant="body1">
                                碳平衡計算：{data.carbonCount ? `${data.carbonCount.toFixed(1)} KG` : 0}
                            </Typography>
                        </DetailCardContent>
                    </DetailCard>
                </DetailRowBox>
            </Box>
            <Button
                variant="contained"
                color="primary"
                href={`${baseUrl}/nft-history/${data.tokenID}`}
                target="_blank"
                rel="noopener noreferrer"
                sx={{ my: 5 }}
            >
                驗證詳情
            </Button>
        </Box>
    )
}

VerifyResult.propTypes = {
    data: PropTypes.object.isRequired,
}

export default VerifyResult
