import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Link, Divider, Tooltip, styled } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import HelpIcon from '@mui/icons-material/Help'
import { renderTime, getContractAddressUrl } from '../../../../util/stringUtil'

const Container = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
}))

const BlockVerificationTable = ({ detailData }) => {
    const contractAddressUrl = getContractAddressUrl(detailData.evmEnv, detailData.contractAddress)

    const renderResult = (result, verifyResultDescription) => {
        const renderText = {
            'Verify success': '驗證成功',
            'VerificationProof signature error': '證據簽章錯誤',
        }
        if (result === 'PASS') {
            return (
                <Box display="flex" alignItems="center">
                    <CheckIcon color="primary" sx={{ marginRight: 1, fontSize: 20 }} />
                    <Typography variant="body2">{renderText[verifyResultDescription]}</Typography>
                </Box>
            )
        } else {
            return (
                <Box display="flex" alignItems="center">
                    <CloseIcon color="error" sx={{ marginRight: 1, fontSize: 20 }} />
                    <Typography variant="body2">{renderText[verifyResultDescription]}</Typography>
                </Box>
            )
        }
    }

    return (
        <Container>
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">整體驗證結果</Typography>
                <Typography variant="body2">
                    {renderResult(detailData.verifyResult, detailData.verifyResultDescription)}
                </Typography>
            </Box>
            <Divider />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">驗證數量</Typography>
                <Typography variant="body2">
                    全部: {detailData.totalCount},成功: {detailData.successCount}, 修改: {detailData.modifiedCount}
                    {detailData.queryType !== 'LOCATOR' && (
                        <>
                            , 增加: {detailData.addedCount},移除: {detailData.removedCount}
                        </>
                    )}
                </Typography>
            </Box>
            <Divider />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">驗證時間</Typography>
                <Typography variant="body2">{renderTime(detailData.uploadTimestamp)}</Typography>
            </Box>
            <Divider />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">樹碼</Typography>
                <Typography variant="body2">{detailData.vin}</Typography>
            </Box>
            <Divider />
            <Box m={2} display="flex" flexDirection="column" gap={1}>
                <Typography variant="body1">合約地址</Typography>
                <Box display="flex" alignItems="center">
                    <Link variant="body2" href={contractAddressUrl} target="_blank">
                        {detailData.contractAddress}
                    </Link>
                    <Tooltip title="可用於確認是否為存證 SPO Server 所使用之合約地址" placement="right">
                        <HelpIcon fontSize="small" sx={{ mx: 1, color: 'primary.main' }} />
                    </Tooltip>
                </Box>
            </Box>
        </Container>
    )
}

BlockVerificationTable.propTypes = {
    detailData: PropTypes.object.isRequired,
}

export default BlockVerificationTable
