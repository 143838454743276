import React from 'react'
import PropTypes from 'prop-types'
import { Box, Typography, Button, Alert, Stack } from '@mui/material'
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

const DetectedSection = ({ isVerifyDone, proofTokenInfo, isDetectFail }) => {
    const onDownloadClick = () => {
        if (proofTokenInfo?.downloadFileLink) {
            proofTokenInfo.downloadFileLink.click()
        }
    }

    return (
        <Box mt={3} mb={4}>
            {isDetectFail ? (
                <Typography variant="body1">查無資料，請確認查詢資訊是否正確</Typography>
            ) : (
                proofTokenInfo.vin && (
                    <Stack spacing={2}>
                        {isVerifyDone && (
                            <Box display="flex" alignItems="center" gap={1}>
                                <CheckCircleIcon fontSize="small" color="common.black" />
                                <Typography color="textPrimary" variant="h6">
                                    驗證完成！
                                </Typography>
                            </Box>
                        )}
                        <Box width={400}>
                            <Alert id="uploaded-proof-info">
                                <Typography variant="body1">{`${'已偵測到的樹碼'} : ${proofTokenInfo.vin}`}</Typography>
                            </Alert>
                        </Box>
                        <Box display="flex" alignItems="center" gap={1}>
                            <Button
                                startIcon={<CloudDownloadIcon />}
                                variant="contained"
                                color="primary"
                                onClick={onDownloadClick}
                                size="small"
                            >
                                下載證據檔案
                            </Button>
                            <Typography variant="body2" color="text.secondary">
                                或於下方列表查看您的驗證結果
                            </Typography>
                        </Box>
                    </Stack>
                )
            )}
        </Box>
    )
}

DetectedSection.propTypes = {
    isVerifyDone: PropTypes.bool,
    proofTokenInfo: PropTypes.object.isRequired,
    isDetectFail: PropTypes.bool,
}

export default DetectedSection
