import React from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { theme } from './theme/Theme'
import Navbar from './component/navbar/Navbar'
import Home from './component/page/Home'
import VerifyDetail from './component/page/VerifyDetail/VerifyDetail'

const App = () => {
    return (
        <ThemeProvider theme={theme}>
            <Navbar />
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path=":treeId" element={<Home />} />
                    <Route path="nft-history/:tokenId" element={<VerifyDetail />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
