import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Typography,
    Tooltip,
    IconButton,
    styled,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'

import DataRow from './VerifyListTableRow/DataRow'
import CollapseRow from './VerifyListTableRow/CollapseRow'
import { arrangeRowDataList } from '../../../../util/verifyListTableDataUtil'
import { getTxHashBaseUrl } from '../../../../util/stringUtil'
import { proofDetailSortAscFunction } from '../../../../util/sortUtil'

const CustomTableContainer = styled(TableContainer)(({ theme }) => ({
    backgroundColor: theme.palette.common.white + 'ab',
    borderRadius: 4,
}))

const VerifyListTable = ({ detailData, handleMoreInfoModalOpen, handleVerifyRawData, handleDownloadRawData }) => {
    const txHashBaseUrl = getTxHashBaseUrl(detailData.mintEnv)

    const renderMoreInfo = (data) => {
        return (
            data.proofExistStatus !== 'REMOVED' && (
                <IconButton onClick={() => handleMoreInfoModalOpen(data)}>
                    <SearchIcon />
                </IconButton>
            )
        )
    }

    const renderClearanceOrder = (data) => {
        return data.txHash !== null ? (
            <Tooltip title="點擊數字前往區塊鏈瀏覽器確認鏈上紀錄" placement="right">
                <Typography
                    id="row-co-link"
                    component="a"
                    variant="body2"
                    href={`${txHashBaseUrl}/${data.txHash}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    color="primary.main"
                    sx={{ textDecoration: 'none' }}
                >
                    {data.clearanceOrder}
                </Typography>
            </Tooltip>
        ) : (
            <>{data.clearanceOrder}</>
        )
    }

    const [rowList, setRowList] = useState([])

    useEffect(() => {
        if (detailData.proofDetailList && detailData.proofDetailList.length > 0) {
            detailData.proofDetailList.sort(proofDetailSortAscFunction)
            setRowList(arrangeRowDataList(detailData.proofDetailList))
        }
    }, [detailData])

    return (
        <>
            <CustomTableContainer>
                <Table id="verify-list-table" aria-label="sticky table">
                    <TableHead>
                        <TableRow id="proof-verification-header-row">
                            <TableCell>
                                <Typography variant="body2">存證時間</Typography>
                                <Typography variant="body2">類別</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2">存證者錢包地址</Typography>
                                <Typography variant="body2">存證者實名認證資訊</Typography>
                            </TableCell>
                            <TableCell>
                                <Typography variant="body2">區塊鏈詳情</Typography>
                                <Typography variant="body2">Index Value</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="body2">驗證狀態</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="body2">驗證原始資料</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="body2">下載原始資料</Typography>
                            </TableCell>
                            <TableCell align="center">
                                <Typography variant="body2">更多資訊</Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rowList.map(({ collapse, dataList }, index) => {
                            return (
                                <React.Fragment key={`verify-list-row-${index}`}>
                                    {collapse && (
                                        <CollapseRow
                                            dataArr={dataList}
                                            renderMoreInfo={renderMoreInfo}
                                            renderClearanceOrder={renderClearanceOrder}
                                        />
                                    )}
                                    {!collapse &&
                                        dataList.map((singleRowData, index) => (
                                            <DataRow
                                                key={`data-row-${index}`}
                                                data={singleRowData}
                                                renderMoreInfo={renderMoreInfo}
                                                handleVerifyRawData={handleVerifyRawData}
                                                handleDownloadRawData={handleDownloadRawData}
                                                renderClearanceOrder={renderClearanceOrder}
                                            />
                                        ))}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </CustomTableContainer>
        </>
    )
}

VerifyListTable.propTypes = {
    detailData: PropTypes.object.isRequired,
    handleMoreInfoModalOpen: PropTypes.func.isRequired,
    handleVerifyRawData: PropTypes.func.isRequired,
    handleDownloadRawData: PropTypes.func.isRequired,
}

export default VerifyListTable
